import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  tenantId: '70000',
  baseUrl: 'https://patient-app.k8s.api.halloalberta.de',
  amplitude: {
    enabled: true,
    apiKey: 'af54c1445e360af9da1b98d0aa591b55',
  },
  oauthConfig: {
    issuer: 'https://auth.halloalberta.de',
    redirectUri: window.location.origin,
    clientId: '87b050b7-f95b-463c-baef-06b846a1bdc8',
    responseType: 'code',
    scope: 'openid offline_access',
    showDebugInformation: true,
    clearHashAfterLogin: true,
  },
  register: {
    privacyPolicyLink: 'https://mediq.de/Nutzungsbedingungen-Mediq-Patienten-App',
    contactEmail: 'app@meinmediq.de',
  },
  signalingServer: 'https://signaling.k8s.api.halloalberta.de',
  rpcConfig: {
    iceServers: [
      { urls: 'stun:stun.stunprotocol.org' },
      {
        urls: 'turn:35.234.75.191:3478',
        username: '2a9d342d-23ab-4828-9560-4f9dfff82ad0',
        credential: '7oxKdqt^jf&oP8eU9FaLNWC*fQ*KuW',
      },
      {
        urls: 'turn:34.159.120.91:3478',
        username: '2a9d342d-23ab-4828-9560-4f9dfff82ad0',
        credential: '7oxKdqt^jf&oP8eU9FaLNWC*fQ*KuW',
      },
      {
        urls: 'turn:35.234.122.203:3478',
        username: '2a9d342d-23ab-4828-9560-4f9dfff82ad0',
        credential: '7oxKdqt^jf&oP8eU9FaLNWC*fQ*KuW',
      },
      {
        urls: 'turn:34.159.40.70:3478',
        username: '2a9d342d-23ab-4828-9560-4f9dfff82ad0',
        credential: '7oxKdqt^jf&oP8eU9FaLNWC*fQ*KuW',
      },
      {
        urls: 'turn:34.159.184.53:3478',
        username: '2a9d342d-23ab-4828-9560-4f9dfff82ad0',
        credential: '7oxKdqt^jf&oP8eU9FaLNWC*fQ*KuW',
      },
      {
        urls: 'turn:34.159.163.209:3478',
        username: '2a9d342d-23ab-4828-9560-4f9dfff82ad0',
        credential: '7oxKdqt^jf&oP8eU9FaLNWC*fQ*KuW',
      },
      // {
      //   urls: 'turn:a.relay.metered.ca:80',
      //   username: '5579da95fe3b5c1a719fe583',
      //   credential: 'Vu1W/aDsmXpIbg2R',
      // },
      // {
      //   urls: 'turn:a.relay.metered.ca:80?transport=tcp',
      //   username: '5579da95fe3b5c1a719fe583',
      //   credential: 'Vu1W/aDsmXpIbg2R',
      // },
      // {
      //   urls: 'turn:a.relay.metered.ca:443',
      //   username: '5579da95fe3b5c1a719fe583',
      //   credential: 'Vu1W/aDsmXpIbg2R',
      // },
      // {
      //   urls: 'turn:a.relay.metered.ca:443?transport=tcp',
      //   username: '5579da95fe3b5c1a719fe583',
      //   credential: 'Vu1W/aDsmXpIbg2R',
      // },
    ],
  },
  pwaMoreInfoLink: 'https://www.mediq.com',
};
